<template>
    <div>
        <b-container fluid class="p-1">
            <b-row>
                <b-col v-for='(file, index) in fileGroup.files' :key='index'>
                    <b-img thumbnail fluid :src="file.url" @click="modal('previewImages', {images: fileGroup.files.map(item => item.url), imageIndex: index})"></b-img>
                </b-col>
            </b-row>
        </b-container>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals> 
    </div>
</template>
<script>
export default {
    props: ['fileGroup']
}
</script>